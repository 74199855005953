@import '@ctrl/ngx-emoji-mart/picker';
@import "https://fonts.googleapis.com/icon?family=Material+Icons";

// Instalação do Tailwind CSS
@tailwind base;
@tailwind components;
@tailwind utilities;


/* You can add global styles to this file, and also import other style files */

.input-select-filter {
  height: 35px !important;
  width: 100% !important;
}

html,
body {
  height: 100%;
  --mat-full-pseudo-checkbox-selected-icon-color: #4213F6;
}

body {
  margin: 0;
  font-family: 'Nunito' !important;
  background: #eff1f5 !important;
  // font-family: Roboto, "Helvetica Neue", sans-serif;
}

.material-icons {
  font-family: 'Material Icons' !important;
}

a {
  text-decoration: none !important;
  color: #000;
}

.wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--background-wrapper);
  height: 100vh;
}

.content-page {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 40px;
}

.content-body {
  width: 100%;
  max-height: calc(100vh - 240px);
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.input-field {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #bdbdbd;
  border-radius: 0.5rem !important;
  transition: border-color 0.3s ease;
  background: #fff;
  resize: none;

  &:focus {
    outline: none;
    border-color: #4213f6;
  }

}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.219);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

@import "../src/assets/css/core/sidebar";
@import "../src/assets/css/core/login";
@import "../src/assets/css/core/header";
@import "../src/assets/css/core/pages";
@import "../src/assets/css/core/components";

//MATERIAL
.mdc-line-ripple::before {
  content: none !important;
}

.mat-mdc-card-content {
  display: flex;
  padding: 0 16px;
}

.input-bg-color {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: none;
}

.all-unset {
  all: unset;
}

::ng-deep .mat-form-field .mat-input-element {
  color: slategray;
}

::ng-deep .mat-form-field .mat-form-field-label {
  color: slategray !important;
}

::ng-deep .mat-form-field .mat-form-field-underline {
  background-color: transparent !important;
}

::ng-deep .mat-form-field .mat-form-field-ripple {
  background-color: slategray;
}

::ng-deep .mat-form-field .mat-form-field-required-marker {
  color: slategray;
}

::ng-deep .mat-form-field.mat-focused .mat-form-field-label {
  color: #ff884d !important;
}

::ng-deep .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #ff884d;
}

::ng-deep .mat-form-field.mat-focused .mat-form-field-required-marker {
  color: #ff884d;
}

::ng-deep .mat-form-field.mat-focused .mat-input-element {
  color: #ff884d;
}

::ng-deep .mat-form-field.mat-form-field-invalid .mat-input-element {
  color: #ffffff;
}

::ng-deep .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff33cc !important;
}

::ng-deep .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff33cc;
}

::ng-deep .mat-form-field.mat-form-field-invalid .mat-form-field-ripple {
  background-color: #ff33cc;
}

//SELECTOR 

.mat-mdc-option .mat-pseudo-checkbox-full {
  border-radius: 50% !important;
}

.mdc-checkbox__background {
  border-radius: 50% !important;
}

//TAGS
.mat-mdc-standard-chip {
  border-radius: 0.5rem !important;
}

input[type="radio"]+label span {
  transition: background .2s,
    transform .2s;
}

input[type="radio"]+label span:hover,
input[type="radio"]+label:hover span {
  transform: scale(1.2);
}

input[type="radio"]:checked+label span {
  background-color: #3490DC; //bg-blue
  box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked+label {
  color: #3490DC; //text-blue
}

// Estilo global para o filtro em SCSS equivalente ao Tailwind CSS

.global-filter {
  border: 1px solid #BDBDBD;
  border-radius: 0.5rem;
}

// ::-webkit-scrollbar
::-webkit-scrollbar {
  width: 8px;

}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4PX;
}

::-webkit-scrollbar-thumb {
  background-color: #c0bfc2;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #c0bfc2;
}

.shadow-global {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}