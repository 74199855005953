
.background-login{
  background-color: black;
  height: 100%;
  background-image: url('../../images/background-login.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.container-form-login{
  margin-top: -50px;
  margin-right: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 300px;
}

.logo-login{
  width: 222px;
  height: 156px;
  background-image: url('../../images/logo.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.card-form-login{
  margin-top: 40px;
  padding: 48px;
  width: 400px;
  border-radius: 24px !important;
  -webkit-box-shadow: 2px 2px 8px -3px rgba(0,0,0,0.67);
  -moz-box-shadow: 2px 2px 8px -3px rgba(0,0,0,0.67);
  box-shadow: 2px 2px 8px -3px rgba(0,0,0,0.67);

  .label{
    margin-bottom: 8px;
  }
}

.mat-mdc-form-field-infix{
  // min-height: 30px;
  border-radius: 20px;
}
.mat-mdc-icon-button.mat-mdc-button-base{
  width: 40px;
  height: 40px;
  padding: 8px;
}
